<template>
  <div class="body">
    <div class="img">
      <div class="image">
        <img
          :src="$$setImgSrc('mqn_submitSuccess.png')"
          alt="">
      </div>
      <div class="imgText">
        <svg width="20" height="20" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20.5" cy="20.5" r="20.5" fill="#FC3F33"/>
          <path d="M18.0827 24.9763C18.5657 24.539 18.9466 24.209 19.3088 23.8603C22.7751 20.5228 26.2467 17.188 29.6995 13.8398C30.5366 13.0296 31.4112 12.6218 32.4361 13.4696C33.2946 14.1779 33.1658 15.1652 32.098 16.198C28.0067 20.1579 23.9019 24.1151 19.7944 28.0643C18.5066 29.3011 17.8064 29.3091 16.5428 28.1045C14.3133 25.9743 12.108 23.8173 9.87587 21.6898C9.03345 20.8877 8.60688 20.0426 9.48417 19.0526C10.2193 18.2236 11.2441 18.3443 12.3146 19.3719C14.2275 21.2096 16.1216 23.0688 18.0827 24.9763Z" fill="white"/>
        </svg>
       <span>提交成功</span>
      </div>
    </div>
    <div class="title">
      感谢您能够完成本次问卷调查
    </div>
    <div class="btn">
      <el-button
        type="danger"
        round
        @click="backList"
      >返回调研列表</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "submit",
  methods: {
    backList() {
      const u = navigator.userAgent;
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      // 跳转
      if (isAndroid) {
        window.android.navBack();
        // let androidParams = "";
        // for (let key in params) {
        //   androidParams += `"${params[key]}",`;
        // }
        // if (androidParams.length) {
        //   let params = androidParams.substring(0, androidParams.length - 1);
        //   eval(`window.android[fn](${params})`);
        // } else {
        //   window.android[fn]();
        // }
      } else if (isiOS) {
        window.webkit.messageHandlers.navBack.postMessage({
          method: "goback"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.body {
  margin: 0 auto;
  .img {
    margin: 80px auto 0;
    >.image{
      margin: 0 auto;
      >img{
        width: 189px;
        height: 131px;
      }
    }
    > .imgText {
      margin-bottom: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        width: 20px;
        padding-right: 5px;
        height: 20px;
      }
      > span {
        font-family: Noto Sans Sharada;
        font-size: 16px;
        text-align: center;
        color: #000000;
        padding-left: 5px;
      }
    }
    > img {
      width: 48vw;
      height: auto;
    }
  }
  .title {
    line-height: 21px;
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-bottom: 7%;
  }
  .btn {
    .el-button.is-round {
      width: 65%;
    }
    .el-button--danger {
      background: linear-gradient(279.66deg, #fc3f33 7.33%, #ff5e54 85.13%);
    }
  }
}
</style>>
